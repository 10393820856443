import React, { useState } from "react";
import { Checkboxes, Select, TextField } from "mui-rff";
import strings from "../../languages/strings";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ObjectTypes, subTypes } from "../utils/data/ObjectTypes";
import DividerText from "../utils/DividerText";
import { Delete } from "@mui/icons-material";
import { FieldArray } from "react-final-form-arrays";

const useStyles = makeStyles((theme: any) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  textFieldSide: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ArtObjectForm = ({ values }: any) => {
  const classes = useStyles();

  const basicProps = {
    variant: "outlined" as "outlined",
    sx: { marginRight: 2, marginBottom: 2 },
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          label={strings.artObjectFields.taxationNumber}
          name="taxationNumber"
          required={true}
          data-cy={"taxation-number"}
          {...basicProps}
        />
        <TextField
          label={strings.artObjectFields.title}
          name="title"
          required={true}
          data-cy={"title"}
          {...basicProps}
        />
        <TextField
          label={strings.artObjectFields.maker}
          name="maker"
          required={true}
          data-cy={"maker"}
          {...basicProps}
        />
        <TextField
          label={strings.artObjectFields.material}
          name="material"
          data-cy={"material"}
          {...basicProps}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <Select
          {...basicProps}
          label={strings.artObjectFields.subObjectType}
          name="subObjectType"
          required={true}
          data-cy={"subObjectType"}
          defaultValue={"empty"}
          data={ObjectTypes.select}
        />
        <Select
          {...basicProps}
          label={strings.artObjectFields.objectType}
          name="objectType"
          data-cy={"ObjectType"}
          data={ObjectTypes.subSelect(values.subObjectType)}
        />
        <TextField
          label={strings.artObjectFields.year}
          name="year"
          data-cy={"year"}
          {...basicProps}
        />
        <TextField
          label={strings.artObjectFields.location}
          name="location"
          data-cy={"location"}
          {...basicProps}
        />
        <Box className={classes.textField}>
          <Checkboxes
            label={"Vink aan indien aanwezig"}
            name="frame"
            data-cy={"frame"}
            data={{ label: strings.artObjectFields.frame, value: false }}
          />
        </Box>
      </Grid>
      <Grid item sm={12}>
        <DividerText>Afmetingen</DividerText>
      </Grid>
      <Grid item md={6} sm={12}>
        {values && values.sizeWithoutFrame ? (
          <TextField
            label={strings.artObjectFields.sizeWithoutFrame}
            name="sizeWithoutFrame"
            data-cy={"sizeWithoutFrame"}
            {...basicProps}
          />
        ) : (
          <>
            <Box
              style={{
                color: "#263238",
                fontSize: "14px",
                lineHeight: "21px",
                letterSpacing: "-0.05px",
                paddingLeft: 2,
              }}
            >
              <Typography>Afmeting (zonder lijst of sokkel)</Typography>
            </Box>
            <Box display={"flex"} paddingTop={1}>
              <TextField
                label={"Hoogte (cm)"}
                type={"number"}
                name="sizeWithoutFrameH"
                data-cy={"sizeWithoutFrameH"}
                {...basicProps}
                className={classes.textFieldSide}
              />
              <TextField
                label={"Breedte (cm)"}
                type={"number"}
                name="sizeWithoutFrameB"
                data-cy={"sizeWithoutFrameB"}
                {...basicProps}
                className={classes.textFieldSide}
              />
              <TextField
                label={"Diepte (cm)"}
                type={"number"}
                name="sizeWithoutFrameD"
                data-cy={"sizeWithoutFrameD"}
                {...basicProps}
              />
            </Box>
          </>
        )}
      </Grid>
      <Grid item md={6} sm={12}>
        {values && values.sizeWithFrame ? (
          <TextField
            label={strings.artObjectFields.sizeWithFrame}
            name="sizeWithFrame"
            data-cy={"sizeWithFrame"}
            {...basicProps}
          />
        ) : (
          <>
            <Box
              style={{
                color: "#263238",
                fontSize: "14px",
                lineHeight: "21px",
                letterSpacing: "-0.05px",
                paddingLeft: 2,
              }}
            >
              <Typography>Afmeting (met lijst of sokkel)</Typography>
            </Box>
            <Box display={"flex"} paddingTop={1}>
              <TextField
                label={"Hoogte (cm)"}
                type={"number"}
                name="sizeWithFrameH"
                data-cy={"sizeWithFrameH"}
                {...basicProps}
                className={classes.textFieldSide}
              />
              <TextField
                label={"Breedte (cm)"}
                type={"number"}
                name="sizeWithFrameB"
                data-cy={"sizeWithFrameB"}
                {...basicProps}
                className={classes.textFieldSide}
              />
              <TextField
                label={"Diepte (cm)"}
                type={"number"}
                name="sizeWithFrameD"
                data-cy={"sizeWithFrameD"}
                {...basicProps}
              />
            </Box>
          </>
        )}
      </Grid>
      <Grid item sm={12}>
        <DividerText>Overig</DividerText>
      </Grid>
      <Grid item md={6} sm={12}>
        <TextField
          label={strings.artObjectFields.edition}
          name="edition"
          data-cy={"edition"}
          {...basicProps}
        />
        <TextField
          label={strings.artObjectFields.collection}
          name="collection"
          data-cy={"collection"}
          {...basicProps}
        />
        <FieldArray name="literature">
          {({ fields }) => (
            <Box>
              {fields.map((name, index) => (
                <Box key={name} marginBottom={2}>
                  <Box display={"flex"}>
                    <TextField
                      label={"Literatuur " + (1 + index)}
                      name={`${name}.label`}
                      data-cy={"literature" + index}
                      variant={"outlined"}
                    />
                    <Box margin={"auto"}>
                      <IconButton
                        edge={"end"}
                        color={"primary"}
                        onClick={() => fields.remove(index)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
              <Button
                data-cy={"add-literature"}
                onClick={() => fields.push({ label: "" })}
              >
                Literatuur toevoegen
              </Button>
            </Box>
          )}
        </FieldArray>
      </Grid>
      <Grid item md={6} sm={12}>
        <TextField
          label={strings.artObjectFields.comments}
          name="comments"
          multiline
          rows={8}
          data-cy={"comments"}
          {...basicProps}
        />
      </Grid>
    </Grid>
  );
};

export default ArtObjectForm;
