import React from "react";
import IArtObject from "../../types/artObject";
import { Box } from "@mui/material";

interface ISizeDisplay {
  artObject: IArtObject;
  withFrame: boolean;
}

const SizeDisplay = ({ artObject, withFrame }: ISizeDisplay) => {
  return (
    <Box>
      {withFrame ? (
        <span data-cy={"sizeWithFrame"}>
          {artObject.sizeWithFrameH}
          {artObject.sizeWithFrameH && artObject.sizeWithFrameB && " x "}
          {artObject.sizeWithFrameB}
          {artObject.sizeWithFrameB && artObject.sizeWithFrameD && " x "}
          {artObject.sizeWithFrameD}
          cm
        </span>
      ) : (
        <span data-cy={"sizeWithoutFrame"}>
          {artObject.sizeWithoutFrameH}
          {artObject.sizeWithoutFrameH && artObject.sizeWithoutFrameB && " x "}
          {artObject.sizeWithoutFrameB}
          {artObject.sizeWithoutFrameB && artObject.sizeWithoutFrameD && " x "}
          {artObject.sizeWithoutFrameD} cm
        </span>
      )}
    </Box>
  );
};
export default SizeDisplay;
